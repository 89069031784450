import React, { Component } from 'react';
import { connect as felaConnect } from 'react-fela';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonPlain,
  Icon,
  IconButton,
  Loading,
  Pager,
  StarRating,
  NoContentPlaceholder,
  TestimonialModalSeller,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  ContentCapitalized,
} from '../../../common/components';
import localStorage from 'store2';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

class TestimonialList extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    viewerId: PropTypes.string,
    premier: PropTypes.object,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    include: PropTypes.oneOf(['for', 'all', 'by']).isRequired,
    onClickEdit: PropTypes.func,
    onClickReply: PropTypes.func,
    onClickDeleteReply: PropTypes.func,
    onClickReport: PropTypes.func,
  };

  static defaultProps = {
    page: 0,
    pageSize: 16,
    onClickEdit: () => {},
    onClickReply: () => {},
    onClickDeleteReply: () => {},
    onClickReport: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      expandedReplies: {},
      showTestimonialModal: props.viewerId !== props.userId && localStorage('showTestimonialModal') === true,
      testimonialModalDidClose: false,
      showTestimonialResubmitModal: false,
      resubmitTestimonial: null,
      showHistoryModal: false,
      historyModalType: 'Testimonial',
      historyModalId: null,
      testimonials: null,
      total: 0,
    };
  }

  componentDidMount() {
    if (localStorage('showTestimonialModal')) {
      localStorage('showTestimonialModal', null);
    }

    const { getTestimonials, userId, viewerId, page, pageSize, getFinalRejected, testimonials } = this.props;

    if (!testimonials) {
      getTestimonials(userId, page, pageSize).then(() => {
        if (!viewerId) {
          return;
        }
        getFinalRejected(userId).then(() => {
          this.updateStateWithTestimonialData();
        });
      });
    } else {
      this.setState({ testimonials });
      this.updateStateWithTestimonialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { page, pageSize, getTestimonials, userId, include } = this.props;

    if (prevProps.page !== page) {
      getTestimonials(userId, page, pageSize);
    }

    if (prevProps.include !== include) {
      getTestimonials(userId, 0, pageSize);
    }
  }

  updateStateWithTestimonialData = () => {
    this.setState(state => ({
      showTestimonialModal: state.showTestimonialModal,
    }));
  };

  toggleExpandedReply(testimonial) {
    this.setState(prevState => {
      const expandedReplies = Object.assign({}, prevState.expandedReplies);
      expandedReplies[testimonial.get('id')] = !expandedReplies[testimonial.get('id')];
      return { expandedReplies };
    });
  }

  onNoContentLeaveTestimonialClick = () => {
    const { showAuthModal, viewerId, userId } = this.props;
    if (!viewerId) {
      localStorage('showTestimonialModal', true);
      showAuthModal();
    } else if (viewerId !== userId) {
      this.setState({ showTestimonialModal: true });
    }
  };

  renderEditButton(testimonial) {
    const { styles, userId } = this.props;
    if (testimonial.get('buyer_id') !== userId) {
      return;
    }

    if (testimonial.get('hidden')) {
      if (testimonial.get('hidden_by_admin')) {
        return;
      }
      return (
        <div className={styles.editButton}>
          <span
            className={styles.unhideLink}
            onClick={() => this.props.updateTestimonialVisibility(testimonial.get('id'))}
          >
            Unhide
          </span>
          <Button buttonType="roundSmall" disabled="disabled" iconLeft={<Icon icon="Pencil" size={16} />}>
            Edit
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.editButton}>
        <span className={styles.hideLink} onClick={() => this.props.updateTestimonialVisibility(testimonial.get('id'))}>
          Hide
        </span>
        <Button
          buttonType="roundSmall"
          onClick={() => this.props.onClickEdit(testimonial)}
          iconLeft={<Icon icon="Pencil" size={16} />}
        >
          Edit
        </Button>
      </div>
    );
  }

  renderReplyHideButtons(testimonial, isReply = false) {
    const { styles } = this.props;

    if (testimonial.get('hidden') && !isReply) {
      if (testimonial.get('hidden_by_admin')) {
        return;
      }
      return (
        <div className={styles.editButton}>
          <span
            className={styles.unhideLink}
            onClick={() => this.props.updateTestimonialVisibility(testimonial.get('id'))}
          >
            Unhide
          </span>
          <Button buttonType="roundSmall" disabled="disabled" iconLeft={<Icon icon="Pencil" size={16} />}>
            Edit
          </Button>
        </div>
      );
    }

    if (testimonial.get('reply') && isReply) {
      const replyHidden = testimonial.get('reply_hidden');
      if ((replyHidden && testimonial.get('reply_hidden_by_admin')) || testimonial.get('hidden')) {
        return;
      }
      return (
        <div className={styles.editButton}>
          <span className={styles.hideLink} onClick={() => this.props.updateReplyVisibility(testimonial.get('id'))}>
            {replyHidden ? 'Unhide' : 'Hide'}
          </span>
          <Button
            buttonType="roundSmall"
            onClick={() => this.props.onClickReply(testimonial)}
            iconLeft={<Icon icon="Pencil" size={16} />}
            disabled={replyHidden}
          >
            Edit
          </Button>
        </div>
      );
    }

    return (
      <Button
        buttonType="roundSmall"
        className={styles.replyButton}
        onClick={() => this.props.onClickReply(testimonial)}
      >
        Reply
      </Button>
    );
  }

  renderReportButton(testimonial, isReply) {
    const { styles, user } = this.props;

    const reportFlag =
      user &&
      user.get('createdFlags') &&
      user
        .get('createdFlags')
        .find(
          flag =>
            flag.getIn(['metadata', 'testimonial']) === testimonial.get('id') &&
            new Date(flag.get('created_at')) > new Date(testimonial.get('updated_at'))
        );
    if (reportFlag) {
      return this.testimonialFlag({ user, testimonial });
    }

    const type = isReply ? 'reply' : 'testimonial';
    if (testimonial.get('hidden') || (isReply && testimonial.get('reply_hidden'))) {
      return;
    }

    return (
      <div className={styles.reportButton}>
        <IconButton
          onClick={() => this.props.onClickReport(testimonial, type)}
          title="Report"
          icon="Warning"
          variant="red"
        />
      </div>
    );
  }

  formatDate(dateString) {
    if (!dateString) {
      return null;
    }

    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
    return formattedDate;
  }

  renderFullReply(testimonial) {
    const { user, styles } = this.props;

    if (!testimonial.get('reply')) {
      return null;
    }

    if (testimonial.get('reply_hidden') && (!user || testimonial.get('seller_id') !== user.get('id'))) {
      return (
        <div className={styles.replyWrapper}>
          <div className={styles.reply}>
            <strong>This reply is no longer visible.</strong>
          </div>
        </div>
      );
    }

    const replyUpdatedAt = testimonial.get('reply_updated_at');
    const replyDate = replyUpdatedAt || testimonial.get('replied_at');
    const isOwnListing = user && testimonial.getIn(['seller', 'id']) === user.get('id');
    const testObj = testimonial.toJS();
    const edits = testObj.history ? testObj.history.filter(edit => edit.type === 'Reply') : [];
    const replyOutlineClass = isOwnListing ? styles.ownReply : styles.reply;
    const replyHidden = testimonial.get('reply_hidden');

    const replyTitleClass = replyHidden ? styles.hiddenReplyTitle : styles.replyTitle;
    const replyClass = replyHidden ? styles.hiddenTestimonialReply : styles.testimonialReply;

    return (
      <div className={styles.replyWrapper}>
        <div className={replyOutlineClass}>
          <div className={styles.rowContainer}>
            {replyHidden ? (
              <div id="hidden-pill" className={styles.dataContainer}>
                <span className={styles.hiddenPill}>Reply Hidden</span>
              </div>
            ) : null}
            <div className={replyTitleClass}>
              {isOwnListing ? 'You' : 'Seller'} replied on {this.formatDate(replyDate)}
            </div>
            <div className={styles.testimonialActions}>
              {isOwnListing
                ? this.renderReplyHideButtons(testimonial, true)
                : this.renderReportButton(testimonial, true)}
            </div>
          </div>
          <div className={replyClass}>{testimonial.get('reply')}</div>
          {edits && edits.length > 0 ? (
            <div style={{ marginTop: '8px' }}>
              <span className={styles.replyEditedOn}>Edited on {this.formatDate(edits[0].originally_written_at)}</span>
              {isOwnListing ? this.renderHistoryButton(testimonial, 'Reply') : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderExpandableReply(testimonial) {
    const { styles, user } = this.props;

    if (!testimonial.get('reply')) {
      return null;
    }

    if (testimonial.get('reply_hidden')) {
      return null;
    }

    const seller = testimonial.get('seller');
    const replyUpdatedAt = testimonial.get('reply_updated_at');
    const replyDate = replyUpdatedAt || testimonial.get('replied_at');
    const formattedReplyDate = this.formatDate(replyDate);
    const isOwnListing = user && testimonial.getIn(['listing', 'seller']) === user.get('id');

    const byLine = (
      <ButtonPlain
        className={styles.byLineButton}
        onClick={this.toggleExpandedReply.bind(this, testimonial)}
        variant="orange"
      >
        <Icon icon="SpeechBubble" size={16} />{' '}
        <ContentCapitalized>
          {seller.get('first_name')} {seller.get('last_initial') ? seller.get('last_initial') : ''}
        </ContentCapitalized>{' '}
        replied on {formattedReplyDate}
      </ButtonPlain>
    );

    return this.state.expandedReplies[testimonial.get('id')] ? (
      <div className={styles.expandedReplyWrapper}>
        <div className={styles.reply}>
          <div className={styles.replyTitleWrapper}>
            <div className={styles.replyTitle}>
              {isOwnListing ? 'Your reply' : replyUpdatedAt ? 'Reply edited ' : 'Replied'} on {formattedReplyDate}
              <br />
              {isOwnListing ? this.renderHistoryButton(testimonial, 'Reply') : null}
            </div>
            <IconButton
              icon="Close"
              size={15}
              className={styles.closeButton}
              onClick={this.toggleExpandedReply.bind(this, testimonial)}
            />
          </div>
          <p className={styles.testimonialReply}>{testimonial.get('reply')}</p>
        </div>
      </div>
    ) : (
      byLine
    );
  }

  renderPurchaseDate(testimonial) {
    const { styles } = this.props;
    return testimonial.get('date_of_purchase') ? (
      <div className={styles.purchaseDate}>Purchase date: {this.formatDate(testimonial.get('date_of_purchase'))}</div>
    ) : null;
  }

  renderConciseTestimonial(testimonial) {
    const { styles, user, showHidden } = this.props;
    const created = new Date(testimonial.get('created_at'));
    const updated = testimonial.get('updated_at') ? new Date(testimonial.get('updated_at')) : null;
    const createdAt = this.formatDate(created);
    const updatedAt = this.formatDate(updated);
    const buyer = testimonial.get('buyer');
    const isOwnListing = testimonial.get('seller_id') === user?.get('id');
    const shouldShowEdited = updatedAt && Math.abs(updated - created) / 1000 > 3;
    const hidden = testimonial.get('hidden');
    let renderedName;
    if (buyer?.get('first_name')) {
      renderedName = `${buyer.get('first_name')} ${buyer.get('last_initial') ? buyer.get('last_initial') : ''}`;
    } else if (testimonial.get('firstname')) {
      renderedName = testimonial.get('firstname');
    } else {
      renderedName = <span className={styles.greyText}>&lt;This user deleted their account&gt;</span>;
    }

    if (hidden && showHidden === false) {
      return;
    }

    return (
      <div
        data-testid="testimonial"
        data-testimonialid={testimonial.get('id')}
        key={testimonial.get('id')}
        className={styles.testimonial}
      >
        <div className={styles.testimonialHeader}>
          <StarRating
            name="rating"
            value={testimonial.get('rating')}
            starSize={16}
            editing={false}
            className={styles.starRating}
          />
          <div className={styles.testimonialAuthor}>
            <ContentCapitalized>{renderedName}</ContentCapitalized>
            {' - '}
            {createdAt}
            {shouldShowEdited ? (
              <span className={styles.testimonialUpdateDate}>
                <span className={styles.dash}>&nbsp;-&nbsp;</span>
                Testimonial edited on {this.formatDate(testimonial.get('updated_at'))}
              </span>
            ) : null}
          </div>
        </div>
        <p>
          {this.renderPurchaseDate(testimonial)}
          {testimonial.get('body')}
        </p>
        {!isOwnListing ? this.renderHistoryButton(testimonial, 'Testimonial') : null}
        {this.testimonialFlag({ user, testimonial })}
        {this.renderExpandableReply(testimonial)}
      </div>
    );
  }

  testimonialFlag = ({ user, testimonial }) => {
    const { styles } = this.props;
    const hasUserFlaggedTestimonial = user
      ? user.get('createdFlags').find(flag => flag.getIn(['metadata', 'testimonial']) === testimonial.get('id'))
      : null;
    if (hasUserFlaggedTestimonial) {
      return (
        <div className={styles.reportedTestimonial}>
          <span>
            <Icon icon="Warning" size="14" /> Reported
          </span>
        </div>
      );
    }
    return null;
  };

  renderFullTestimonialNew(testimonial) {
    const { user, styles, showHidden } = this.props;
    const listing = testimonial.get('listing');
    const buyer = testimonial.get('buyer');
    const seller = testimonial.get('seller');
    const isOwnListing = user && testimonial.get('seller_id') === user.get('id');
    const isOwnTestimonial = user && user.get('id') === testimonial.get('user_id');
    const author = isOwnListing ? buyer : seller;
    const created = new Date(testimonial.get('created_at'));
    const updated = testimonial.get('updated_at') ? new Date(testimonial.get('updated_at')) : null;
    const createdAt = this.formatDate(created);
    const updatedAt = this.formatDate(updated);
    const shouldShowEdited = testimonial.get('edited') ? testimonial.get('edited') : false;
    const hidden = testimonial.get('hidden');
    let renderedName;
    if (author?.get('first_name')) {
      renderedName = `${author.get('first_name')} ${author.get('last_initial') ? author.get('last_initial') : ''}`;
    } else if (author?.get('firstname')) {
      renderedName = author.get('firstname');
    } else {
      renderedName = (
        <span className={styles.greyText}>
          This user deleted
          <br />
          their account
        </span>
      );
    }
    const responses = testimonial.get('responses');
    if (responses && responses.size) {
      // Set testimonial to be the most recent re-submit for approval
      testimonial = responses.get(-1);
    }

    if (hidden && showHidden === false) {
      return;
    }
    const wrapperClass = testimonial.get('hidden')
      ? styles.hiddenFullTestimonialWrapper
      : styles.fullTestimonialWrapper;
    const listingUrl = listing ? `/listings/${listing.get('id')}` : '';
    const profileUrl = author ? `/profile/${author.get('id')}` : null;
    return (
      <div
        data-testid="testimonial"
        data-testimonialid={testimonial.get('id')}
        key={testimonial.get('id')}
        className={wrapperClass}
      >
        <div className={styles.rowContainer}>
          {testimonial.get('hidden') ? (
            <div id="hidden-pill" className={styles.dataContainer}>
              <span className={styles.hiddenPill}>Testimonial Hidden</span>
            </div>
          ) : null}
          <div className={styles.dataContainer}>
            <span className={styles.label}>Date</span>
            <span className={styles.data}>{createdAt}</span>
          </div>
          <div className={styles.dataContainer}>
            <span className={styles.label}>Rating</span>
            <span className={styles.data}>
              {!hidden || (hidden && isOwnTestimonial) ? (
                <StarRating
                  name="rating"
                  value={testimonial.get('rating')}
                  starCount={5}
                  starSize={15}
                  editing={false}
                  className={styles.starRatingRightAligned}
                />
              ) : null}
            </span>
          </div>
          <div className={styles.dataContainer}>
            <span className={styles.label}>{isOwnListing ? 'Left by' : 'Sold By'}</span>
            <span className={styles.data}>
              {profileUrl && (!hidden || (hidden && isOwnTestimonial)) ? (
                <a href={profileUrl} target="_blank" rel="noreferrer">
                  {renderedName}
                </a>
              ) : (
                renderedName
              )}
            </span>
          </div>
          {listing ? (
            <div className={styles.dataContainer}>
              <span className={styles.label}>For listing</span>
              <span className={styles.data}>
                <a href={listingUrl}>{listing.get('name')}</a>
              </span>
            </div>
          ) : null}
          <div id="testimonial-actions" className={styles.testimonialActions}>
            {this.renderTestimonialActions(isOwnListing, testimonial)}
          </div>
        </div>
        <div className={styles.testimonialbody}>
          <div>
            {!hidden || (hidden && isOwnTestimonial) ? this.renderPurchaseDate(testimonial) : null}
            {!hidden || (hidden && isOwnTestimonial) ? (
              testimonial.get('body')
            ) : (
              <strong>This testimonial is no longer visible.</strong>
            )}
          </div>
          {shouldShowEdited ? (
            <div>
              <br />
              <span className={styles.updatedDate}>
                <strong>Testimonial edited on {updatedAt}</strong>
              </span>
              {!isOwnListing ? this.renderHistoryButton(testimonial, 'Testimonial') : null}
            </div>
          ) : null}
          {this.renderFullReply(testimonial)}
          <hr className={styles.testimonialHr} />
        </div>
      </div>
    );
  }

  renderTestimonialActions = (isOwnListing, testimonial) => {
    if (isOwnListing) {
      if (testimonial.get('reply')) {
        return this.renderReportButton(testimonial);
      }
      return (
        <>
          {this.renderReportButton(testimonial)}
          {this.renderReplyHideButtons(testimonial)}
        </>
      );
    } else {
      if (!['pending', 'rejected', 'final rejected'].includes(testimonial.get('status'))) {
        return this.renderEditButton(testimonial);
      }
      return null;
    }
  };

  renderTestimonial = testimonial => {
    const { include, showFull } = this.props;

    if (showFull) {
      return this.renderFullTestimonialNew(testimonial);
    } else if (include === 'for') {
      return this.renderConciseTestimonial(testimonial);
    } else {
      return this.renderFullTestimonialNew(testimonial);
    }
  };

  renderLeaveTestimonialButton() {
    const { userId, viewerId, showAuthModal, isEmailVerified, canLeaveTestimonial } = this.props;

    if (!showAuthModal) {
      return null;
    } else if (canLeaveTestimonial === false) {
      return (
        <ButtonPlain
          data-testid="leave-testimonial-button"
          disabled={true}
          title="You have already left a testimonial for this user"
        >
          Leave a Testimonial
        </ButtonPlain>
      );
    } else if (!viewerId) {
      return (
        <ButtonPlain
          data-testid="leave-testimonial-button"
          onClick={() => {
            localStorage('showTestimonialModal', true);
            showAuthModal();
          }}
        >
          Leave a Testimonial
        </ButtonPlain>
      );
    } else if (viewerId !== userId) {
      if (isEmailVerified) {
        return (
          <ButtonPlain
            data-testid="leave-testimonial-button"
            onClick={() => {
              this.setState({ showTestimonialModal: true });
            }}
          >
            Leave a Testimonial
          </ButtonPlain>
        );
      } else {
        return (
          <ButtonPlain data-testid="leave-testimonial-button" onClick={this.props.handleEmailIsNotVerified}>
            Leave a Testimonial
          </ButtonPlain>
        );
      }
    } else if (viewerId !== userId) {
      return (
        <ButtonPlain
          data-testid="leave-testimonial-button"
          disabled={true}
          title="You have already left a testimonial for this user"
        >
          Leave a Testimonial
        </ButtonPlain>
      );
    }
  }

  renderTestimonialModal() {
    const { premier, user } = this.props;
    const { showTestimonialModal, editing, replying } = this.state;
    const testimonial = editing ? editing : replying;

    if (!premier || !user) {
      return null;
    }

    return (
      <TestimonialModalSeller
        isOpen={showTestimonialModal}
        seller={premier}
        user={user}
        onClose={() => {
          this.setState({ showTestimonialModal: false, testimonialModalDidClose: true });
        }}
        addIdToTestimonials={this.addIdToTestimonials}
        edits={testimonial?.get('edits')}
      />
    );
  }

  renderHistoryModal() {
    const { showHistoryModal, historyModalType, historyModalId } = this.state;
    const { testimonials } = this.props;
    if (!historyModalId) {
      return null;
    }
    const testObj = testimonials.toJS();
    const testimonial = testObj.filter(obj => {
      return obj.id === historyModalId;
    });
    if (!testimonial.length) {
      return null;
    }
    const edits = testimonial[0].history.filter(edit => edit.type === historyModalType);
    if (edits.length === 0) {
      return null;
    }

    return (
      <Modal
        isOpen={showHistoryModal}
        onClose={() =>
          this.setState({ showHistoryModal: false, historyModalType: 'Testimonial', historyModalId: null })
        }
        closeOnOutsideClick
        closeOnEscape
      >
        <ModalHeader
          title={'Viewing Update History'}
          subTitle={
            'In order to provide transparency, the full history of all edits and updates is viewable to everyone.'
          }
        />
        <ModalBody>{edits.map((edit, index) => this.renderHistory(edit, index))}</ModalBody>
        <ModalFooter
          actions={[
            <ButtonPlain
              type="button"
              variant="red"
              onClick={() =>
                this.setState({ showHistoryModal: false, historyModalType: 'Testimonial', historyModalId: null })
              }
            >
              Close
            </ButtonPlain>,
          ]}
        />
      </Modal>
    );
  }

  renderHistory = (testimonial, index) => {
    const { styles } = this.props;
    return (
      <div key={`${testimonial.id}-edit${index}`} className={styles.testimonial}>
        <div className={styles.testimonialHistoryHeader}>
          <div className={styles.testimonialAuthor}>
            <span className={styles.testimonialWrittenOn}>
              <strong>Written on {this.formatDate(testimonial.originally_written_at)}</strong>
            </span>
          </div>
          {testimonial.rating ? (
            <StarRating
              name="rating"
              value={testimonial.rating}
              starSize={16}
              editing={false}
              className={styles.starRating}
            />
          ) : null}
        </div>
        <div className={styles.testimonialbody}>{testimonial.body}</div>
      </div>
    );
  };

  renderPager() {
    const { pageSize, total } = this.props;
    return <Pager pageSize={pageSize} totalItems={total} />;
  }

  renderHistoryButton(testimonial, type) {
    const { styles } = this.props;
    const testObj = testimonial.toJS();
    const edits = testObj.history ? testObj.history.filter(edit => edit.type === type).length : 0;

    if (!edits) {
      return null;
    }

    return (
      <div className={styles.historyButton}>
        {' '}
        <ButtonPlain
          onClick={() => this.setState({ showHistoryModal: true, historyModalType: type, historyModalId: testObj.id })}
        >
          View history
        </ButtonPlain>
      </div>
    );
  }

  renderNoContentMessage() {
    const { tab } = this.props;
    let title = "This user hasn't received any testimonials yet";
    let description = "When they do, they'll show up here";
    if (tab) {
      title = tab === 'left' ? "You haven't written any testimonials yet" : "You haven't received any testimonials yet";
      description = "When you do, they'll show up here";
    }

    return (
      <div>
        {this.renderLeaveTestimonialButton()}
        <NoContentPlaceholder description={description} icon="StarsOutline" title={title} />
      </div>
    );
  }

  render() {
    const { testimonials, styles, noHeader, className } = this.props;
    if (!testimonials) {
      return <Loading center dark />;
    }
    return (
      <div className={classNames([styles.root, className])}>
        {!testimonials || testimonials.size === 0 ? (
          <div>{this.renderNoContentMessage()}</div>
        ) : (
          <div>
            {noHeader ? null : (
              <div className={styles.header}>
                <h1 className={styles.h1}>Testimonials</h1>
                {this.renderLeaveTestimonialButton()}
              </div>
            )}
            {testimonials.map(this.renderTestimonial)}
            {this.renderPager()}
          </div>
        )}
        {this.renderTestimonialModal()}
        {this.renderHistoryModal()}
      </div>
    );
  }
}

const styles = props => ({
  root: {
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
  },
  noContentPlaceholder: {
    marginTop: '34px !important',
  },
  header: {
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #DBDBDB',
  },
  greyText: {
    color: '#999',
  },
  h1: {
    ...props.theme.typography.titleText,
  },
  hiddenFullTestimonialWrapper: {
    borderTop: '1px solid #DBDBDB',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0 0 0',
    overflow: 'hidden',
    color: props.theme.colors.brown,
    fontSize: '14px',
    ':first-child': {
      border: 'none',
    },
    '> div div:not(#hidden-pill, #testimonial-actions)': {
      opacity: '0.5',
    },
  },
  fullTestimonialWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0 0 0',
    overflow: 'hidden',
    color: props.theme.colors.brown,
    fontSize: '14px',
    ':first-child': {
      border: 'none',
    },
  },
  testimonial: {
    borderTop: '1px solid #DBDBDB',
    padding: '16px 0',
    color: props.theme.colors.brown,
    fontSize: '14px',
    '> p': {
      marginTop: '16px',
      marginBottom: '16px',
      lineHeight: '20px',
    },
  },
  testimonialHistoryHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '8px',
    '> * + *': {
      marginLeft: '8px',
    },
    '> .dv-star-rating': {
      display: 'flex !important',
      flexDirection: 'row-reverse',
      margin: '4px 0 16px 0',

      [props.theme.breakpoints.mobile]: {
        marginBottom: 0,
      },
    },
  },
  testimonialHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '8px',
    '> * + *': {
      marginLeft: '8px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '> .dv-star-rating': {
      display: 'flex !important',
      flexDirection: 'row-reverse',
      margin: '0 8px 8px 0',

      [props.theme.breakpoints.mobile]: {
        marginBottom: 0,
      },
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  hideLink: {
    color: props.theme.colors.orange,
    textDecoration: 'underline',
    fontWeight: 'bold',
    paddingRight: '12px',
    cursor: 'pointer',
  },
  unhideLink: {
    color: props.theme.colors.blue,
    textDecoration: 'underline',
    fontWeight: 'bold',
    paddingRight: '12px',
    cursor: 'pointer',
  },
  hiddenPill: {
    backgroundColor: props.theme.colors.orange,
    borderRadius: '20px',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '4px 20px',
    color: '#fff',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '20px',
  },
  label: {
    marginBottom: '4px',
  },
  data: {
    fontWeight: 'bold',
  },
  testimonialbody: {
    textAlign: 'left',
    marginTop: '16px',
  },
  starRating: {
    '> .dv-star-rating': {
      display: 'flex',
    },
    '> .dv-star-rating-star': {
      marginRight: '4px',
    },
  },
  starRatingRightAligned: {
    '> .dv-star-rating-star': {
      marginLeft: '4px',
    },
  },
  testimonialAuthor: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    fontWeight: props.theme.typography.sansBold,
    lineHeight: '20px',
    whiteSpace: 'pre-wrap',

    [props.theme.breakpoints.mobile]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  testimonialUpdateDate: {
    display: 'inline-block',
    fontWeight: props.theme.typography.sansRegular,
    margin: 0,
    color: '#888888',
    [props.theme.breakpoints.mobile]: {
      marginRight: '8px',
    },
  },
  dash: {
    display: 'none',
    [props.theme.breakpoints.mobile]: {
      display: 'inline-block',
    },
  },
  testimonialWrittenOn: {
    display: 'inline-block',
    fontWeight: props.theme.typography.sansRegular,
    marginLeft: 0,
  },
  avatars: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    '> * + *': {
      marginLeft: '10px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'column',
      width: '25%',
      marginBottom: 0,
      '> * + *': {
        marginLeft: 0,
        marginTop: '32px',
      },
    },
  },
  avatarGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    '> * + *': {
      marginLeft: '16px',
    },
    [props.theme.breakpoints.mobile]: {
      width: '100%',
    },
  },
  avatarGroupInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '> *': {
      whiteSpace: 'nowrap',
      width: '65%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '> * + *': {
      marginTop: '6px',
    },
  },
  avatarGroupInfoPrice: {
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.green,
  },
  fullTestimonial: {
    width: '100%',
    [props.theme.breakpoints.mobile]: {
      width: '75%',
      marginLeft: '30px',
    },
  },
  testimonialUpdateText: {
    display: 'flex',
    flexDirection: 'center',
    alignItems: 'center',
    [props.theme.breakpoints.mobile]: {},
  },
  fullTestimonialHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  updatedDate: {
    opacity: 0.5,
    paddingTop: '8px',
    marginLeft: 0,
  },
  testimonialHr: {
    marginTop: '30px',
    borderColor: '#baa995',
  },
  fullTestimonialBody: {
    lineHeight: '1.5',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  hiddenReplyWrapper: {
    padding: '30px 0 0',
    '> div div:not(#hidden-pill, #testimonial-actions)': {
      opacity: '0.5',
    },
  },
  replyWrapper: {
    padding: '30px 0 0',
  },
  expandedReplyWrapper: {
    padding: '10px 0 0',
  },
  ownReply: {
    padding: '12px 20px',
    borderTopRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: '#dff5f6',
  },
  reply: {
    padding: '12px 20px',
    borderTopRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: props.theme.colors.darkerTan,
  },
  hiddenReplyTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: '0.5',
  },
  replyTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  replyTitle: {
    color: '#232323',
    fontWeight: props.theme.typography.sansBold,
    display: 'flex',
    flexDirection: 'row',
  },
  deleteReplyButton: {
    marginTop: '10px',
  },
  byLineButton: {
    display: 'flex !important',
    alignItems: 'center',
    marginTop: '10px',
    '> span': {
      marginRight: '8px',
    },
  },
  closeButton: {
    color: props.theme.colors.orange,
    padding: '4px',
    transition: '0.2s all',
    ':active': {
      outline: 'none',
    },
    ':hover': {
      opacity: 0.5,
    },
  },
  replyButton: {
    marginTop: '16px',
    width: 'fit-content',
    padding: '4px 24px !important',
  },
  editButton: {
    display: 'flex',
    flexDirection: 'column',
    '@media all and (min-width: 768px)': {
      flexDirection: 'row',
    },
    '> button': {
      padding: '2px 4px',
      fontSize: 'inherit',
      marginTop: '16px',
      '@media all and (min-width: 768px)': {
        marginTop: '0',
      },
      '> div > span': {
        marginRight: '4px',
      },
    },
  },
  testimonialActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    flexGrow: '1',
    '> div': {
      opacity: '1.0 !important',
    },
    '@media all and (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  reportButton: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 12px 0',
    '> button': {
      color: `${props.theme.colors.red} !important`,
      fontWeight: 'bold',
    },
  },
  hiddenTestimonialReply: {
    lineHeight: '1.5',
    opacity: '0.5',
  },
  testimonialReply: {
    lineHeight: '1.5',
  },
  replyEditedOn: {
    fontWeight: 'bold',
    paddingRight: '10px',
  },
  testimonialRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  statusPill: {
    width: 'fit-content',
    textTransform: 'uppercase',
    marginLeft: 0,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '3px 10px',
    borderRadius: '12px',
  },
  historyButton: {
    display: 'inline-block',
    marginTop: '8px',
    marginLeft: '8px',

    [props.theme.breakpoints.mobile]: {
      marginTop: 0,
    },
  },
  rejectedTestimonial: {
    color: `${props.theme.colors.red} !important`,
  },
  reportedTestimonial: {
    display: 'inline-flex',
    backgroundColor: props.theme.colors.red,
    color: props.theme.colors.white,
    borderRadius: props.theme.globalBorderRadius,
    padding: '4px 12px',
    marginTop: '13px',
    marginRight: '10px',
    height: 'fit-content',
    '> span': {
      display: 'flex',
      '> span': {
        marginRight: '6px',
      },
    },
  },
  purchaseDate: {
    fontStyle: 'italic',
  },
});

export default withRouter(felaConnect(styles)(TestimonialList));
